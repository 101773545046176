<template>
  <div>
    <slot :viewFile="viewFile" />
    <v-overlay :opacity="1" v-model="dialog">
      <v-dialog
        v-model="dialog"
        :attach="attach"
        fullscreen
        overlay-opacity="1"
        overlay-color="#000"
      >
        <div class="view-container">
          <v-btn
            dark
            small
            depressed
            outlined
            icon
            @click="onClose"
            class="btn-file-view"
          >
            <v-icon size="24">mdi-close</v-icon>
          </v-btn>
          <div class="d-flex justify-center align-center file-container">
            <ImageView v-if="type == 'image'" :file="fileView" />
            <!-- <PdfView v-else-if="type == 'pdf'" :src="fileView.path" /> -->
            <VideoView v-else-if="type == 'video'" :file="fileView" />
            <div v-else class="white--text">Không hỗ trợ xem trước</div>
          </div>
          <div v-if="items && items.length > 1">
            <v-btn
              dark
              depressed
              large
              icon
              @click="onSetPreImage()"
              class="pa-2"
              :disabled="currentIndex < 1"
            >
              <v-icon size="24">mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn
              dark
              depressed
              large
              icon
              @click="onSetNextImage()"
              class="pa-2"
              :disabled="currentIndex >= items.length - 1"
            >
              <v-icon size="24">mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </div>
      </v-dialog>
    </v-overlay>
  </div>
</template>

<script>
export default {
  components: {
    ImageView: () => import("./View/ImageView.vue"),
    // PdfView: () => import("./View/PdfView.vue"),
    VideoView: () => import("./View/VideoView.vue"),
  },
  props: { attach: {}, items: { type: Array, default: () => [] } },
  provide() {
    return { viewFile: this.viewFile };
  },
  data: () => ({
    dialog: false,
    fileView: {},
    isShowImageView: false,
    isShowPdfView: false,
    isShowVideoView: false,
    type: "",
  }),
  computed: {
    currentIndex() {
      return this.items.findIndex((x) => x.id === this.fileView.id);
    },
  },
  methods: {
    onSetPreImage() {
      if (this.currentIndex < 1) return;
      this.viewFile(this.items[this.currentIndex - 1], this.type);
    },
    onSetNextImage() {
      if (this.currentIndex >= this.items.length - 1) return;
      this.viewFile(this.items[this.currentIndex + 1], this.type);
    },
    onClose() {
      this.dialog = false;
      this.fileView = {};
    },
    viewFile(file, type) {
      this.fileView = file;
      this.dialog = true;
      this.type = type;
      if (type == "image") {
        this.isShowImageView = true;
      } else if (type == "pdf") {
        this.isShowPdfView = true;
      } else if (type == "video") {
        this.isShowVideoView = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.view-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #00000075;

  .btn-file-view {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .file-container {
    width: 75vw;
    height: 75vh;
  }
}
</style>
